import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M4514 6956 c-3 -13 -4 -82 -2 -153 l3 -128 318 -3 c174 -1 317 -5
317 -7 0 -5 -101 -147 -127 -180 -10 -12 -127 -174 -166 -230 -16 -22 -43 -60
-62 -85 -19 -25 -51 -70 -73 -100 -21 -30 -134 -187 -250 -348 l-211 -292 191
2 192 3 108 150 c60 83 127 177 150 210 23 33 44 62 48 65 3 3 36 48 72 100
37 52 74 104 82 115 9 11 60 81 113 155 53 74 149 207 213 295 64 88 141 193
170 234 29 41 77 107 107 148 l54 73 -620 0 -621 0 -6 -24z"
          />
          <path
            d="M3020 4520 l0 -520 100 0 100 0 0 155 0 154 42 61 c22 33 42 59 44
58 1 -2 50 -99 109 -216 l107 -213 114 2 c110 1 124 4 105 24 -4 6 -39 69 -76
140 -71 137 -121 230 -188 356 l-41 76 137 217 c75 120 137 220 137 222 0 2
-49 4 -109 4 l-109 0 -73 -117 c-87 -141 -115 -184 -129 -200 -5 -7 -10 -15
-10 -19 0 -10 -41 -64 -49 -64 -4 0 -8 17 -8 38 0 20 -1 110 -2 200 l-1 162
-100 0 -100 0 0 -520z"
          />
          <path
            d="M7200 4520 l0 -520 95 0 95 0 0 40 c0 22 3 40 6 40 3 0 16 -13 30
-29 13 -16 39 -36 58 -45 37 -18 131 -21 169 -7 39 15 97 71 113 110 58 135
57 457 -1 572 -43 84 -101 122 -190 122 -68 0 -95 -11 -142 -56 l-33 -31 0
162 0 162 -100 0 -100 0 0 -520z m364 114 c32 -32 39 -61 44 -194 5 -156 -7
-242 -40 -277 -18 -19 -31 -23 -67 -21 -79 5 -101 60 -101 253 0 197 24 255
106 255 25 0 49 -6 58 -16z"
          />
          <path
            d="M4679 4800 c-127 -23 -202 -106 -203 -225 -1 -68 18 -116 61 -156 35
-33 66 -49 182 -93 118 -44 131 -56 131 -117 0 -39 -4 -51 -26 -68 -30 -23
-88 -28 -127 -10 -27 12 -42 39 -52 93 l-7 36 -84 0 -84 0 0 -52 c1 -94 51
-168 138 -202 56 -21 211 -22 273 -1 60 21 117 69 140 119 25 55 25 157 0 212
-25 55 -68 85 -176 123 -49 18 -104 38 -122 46 -97 43 -72 164 34 165 52 0 81
-27 90 -82 l6 -39 86 3 86 3 -3 45 c-6 94 -39 147 -113 179 -51 23 -164 33
-230 21z"
          />
          <path
            d="M5360 4800 c-65 -12 -93 -24 -134 -61 -73 -64 -106 -175 -106 -352 1
-228 52 -338 177 -383 21 -7 79 -13 128 -13 108 -1 173 24 223 85 65 78 76
128 76 324 0 160 -2 180 -23 235 -29 73 -69 117 -130 143 -55 24 -149 33 -211
22z m123 -148 c36 -39 47 -100 47 -251 0 -216 -25 -281 -107 -281 -46 0 -80
36 -94 99 -7 32 -10 116 -7 209 4 157 9 182 46 225 21 24 93 23 115 -1z"
          />
          <path
            d="M6020 4801 c-136 -23 -210 -103 -210 -229 0 -74 31 -137 85 -174 22
-15 86 -44 143 -65 57 -21 112 -46 123 -56 35 -33 37 -89 4 -131 -10 -13 -31
-21 -68 -24 -72 -6 -107 22 -114 91 l-6 47 -88 0 -89 0 1 -42 c1 -73 31 -139
78 -175 53 -41 102 -54 201 -54 117 0 174 20 237 80 60 57 79 192 38 270 -32
63 -56 77 -241 145 -91 34 -114 55 -114 106 0 51 35 80 95 80 50 0 75 -23 84
-78 l6 -37 85 0 85 0 3 32 c8 86 -56 181 -136 202 -56 14 -152 20 -202 12z"
          />
          <path
            d="M3780 4471 c0 -175 4 -331 10 -350 26 -92 83 -131 188 -131 77 0 130
22 174 74 l28 31 0 -47 0 -48 95 0 95 0 0 395 0 395 -100 0 -99 0 -3 -299 -3
-299 -24 -26 c-20 -21 -34 -26 -71 -26 -85 0 -84 -4 -90 344 l-5 301 -97 2
-98 1 0 -317z"
          />
          <path
            d="M6480 4473 c0 -188 4 -333 10 -355 13 -44 52 -93 91 -113 36 -19 149
-20 192 -1 18 7 48 30 67 50 l35 37 5 -43 5 -43 93 -3 92 -3 0 396 0 396 -97
-3 -98 -3 -5 -297 -5 -296 -24 -26 c-20 -21 -34 -26 -71 -26 -85 0 -84 -4 -90
344 l-5 301 -97 3 -98 3 0 -318z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
